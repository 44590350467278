import { useProducts } from "@hooks";
import { Tooltip, Typography } from "@mui/material";
import { Product } from "@types";

export const DeviceActivationType = ({ product }: { product: Product }) => {
    return (
        <>
            <Tooltip
                sx={{ display: "inline-block", cursor: "help" }}
                title={`This product lets you used upto ${product.allowed_workers} Worker(s) per device`}
            >
                <Typography color="green">{product.name}</Typography>
            </Tooltip>
        </>
    );
};
export const DeviceActivationTypeSmall = ({ product }: { product: Product }) => {
    const { getShortName } = useProducts();

    return (
        <>
            <Tooltip
                sx={{ display: "inline-block", cursor: "help" }}
                title={`This product lets you used upto ${product.allowed_workers} Worker(s) per device`}
            >
                <Typography fontWeight={"bold"} color="green">
                    [{getShortName(product)[0]}]
                </Typography>
            </Tooltip>
        </>
    );
};
