/* eslint-disable @typescript-eslint/no-non-null-assertion */
import PhonelinkSetupIcon from "@mui/icons-material/PhonelinkSetup";
import DeleteIcon from "@mui/icons-material/Delete";
import { Checkbox, Icon, IconButton, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import NextLink from "next/link";
import React, { useCallback } from "react";
import { Crown, DeviceActivationType, DeviceActivationTypeSmall } from "@components/Shared";
import { Device, LicenseStatus } from "..";
import { useProducts } from "@lib/provider";
// import { getProductFromId } from "@lib/utils";

interface DeviceTableItemProps {
    device: Device;
    handleCheckboxChange: (index: number) => void;
    isSelected: boolean;
    handleIndividualDelete: () => void;
    handleIndividualEdit: () => void;
    isSmallScreen: boolean;
}

export const DeviceTableItem: React.FC<DeviceTableItemProps> = ({
    handleCheckboxChange,
    isSelected,
    handleIndividualDelete,
    handleIndividualEdit,
    device: { device_name, id, license_status, uses_own_config, product_id },
    isSmallScreen,
}) => {
    const { getProductFromId } = useProducts();
    const licenseStatus = useCallback(() => {
        switch (license_status) {
            case LicenseStatus.INACTIVE:
                return { color: "#ef5350", type: "Inactive" };
            case LicenseStatus.ACTIVE:
                return { color: "green", type: "Active", product: getProductFromId(product_id) };
            default:
                return { color: "orange", type: "Partial" };
        }
    }, [getProductFromId, license_status, product_id]);

    return (
        <TableRow selected={isSelected}>
            <TableCell padding="checkbox">
                <Checkbox color="primary" onChange={() => handleCheckboxChange(id)} checked={isSelected} />
            </TableCell>
            <TableCell sx={{ px: "8px" }} align="left">
                {isSmallScreen &&
                    (licenseStatus().product ? (
                        <>
                            <DeviceActivationTypeSmall product={licenseStatus().product!} />{" "}
                        </>
                    ) : (
                        <div
                            style={{
                                display: "inline-flex",
                                width: "12px",
                                height: "12px",
                                backgroundColor: licenseStatus().color,
                                borderRadius: "50%",
                                marginRight: "4px",
                                position: "relative",
                                bottom: "-1px",
                            }}
                        />
                    ))}
                {device_name}
                {/* {licenseStatus().initial ? `[${licenseStatus().initial}]` : ""} */}
                {uses_own_config && (
                    <Tooltip title={`${device_name} uses it's local config`}>
                        <IconButton sx={{ p: "4px" }} disableRipple>
                            <Icon>
                                <Crown />
                            </Icon>
                        </IconButton>
                    </Tooltip>
                )}
            </TableCell>
            {!isSmallScreen && (
                <TableCell sx={{ fontSize: "16px", color: licenseStatus().color, px: "8px" }} align="left">
                    {licenseStatus().product ? (
                        <DeviceActivationType product={licenseStatus().product!} />
                    ) : (
                        licenseStatus().type
                    )}
                </TableCell>
            )}
            <TableCell sx={{ px: 0 }} align="center">
                {/* <Tooltip title="Edit" sx={{ display: "inline-block" }}>
                    <IconButton sx={{ p: "4px" }} onClick={() => handleIndividualEdit()}>
                    <EditIcon />
                    </IconButton>
                </Tooltip> */}
                <Tooltip title="Delete" sx={{ display: "inline-block" }}>
                    <IconButton sx={{ p: "4px" }} onClick={() => handleIndividualDelete()}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
                <NextLink href="/device/[id]" as={`/device/${id}`}>
                    <Tooltip title="Manage Device">
                        <IconButton sx={{ p: "4px", mr: { sm: "-8px", md: "-16px" } }}>
                            <PhonelinkSetupIcon />
                        </IconButton>
                    </Tooltip>
                </NextLink>
            </TableCell>
        </TableRow>
    );
};
