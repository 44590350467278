import axiosInstance from "@lib/axios";
import { Instance } from "..";

export const editLicenseDeactivate = async ({ instance_id }: Instance): Promise<void> => {
    await axiosInstance.post("/instance/deactivateLicense", { instance_id });
};
export const editLicenseActivate = async ({
    instance_id,
    product_id,
}: Instance & { product_id: string }): Promise<void> => {
    await axiosInstance.post("/instance/activateLicense", { instance_id, product_id });
};
