/* eslint-disable @typescript-eslint/no-empty-function */

import { getColor, setColor } from "@lib/utils";
import { createTheme, ThemeProvider } from "@mui/material";
import { getTheme } from "@themes";
import React, { createContext, useCallback, useEffect, useMemo, useState } from "react";
import { ColorModes, ColorModeType } from "..";

export const ColorModeContext = createContext<ColorModeType>({
    color: "light",
    toggleColorMode: () => {},
    product: "atlas",
    changeProduct: () => {},
});

export const ColorModeProvider: React.FC = ({ children }) => {
    const [mode, setMode] = useState<ColorModes>({ product: "atlas", color: "light" });

    useEffect(() => {
        const theme = getColor();

        if (theme && (theme.color !== mode.color || theme.product !== mode.product)) {
            setMode(theme as ColorModes);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const colorMode: ColorModeType = {
        color: mode.color,
        toggleColorMode: useCallback(() => {
            const color = mode.color === "light" ? "dark" : "light";

            setColor({ color, product: mode.product });
            setMode({ color, product: mode.product });
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [mode]),
        product: mode.product,
        changeProduct: useCallback(
            (product) => {
                setColor({ product, color: mode.color });
                setMode({ product, color: mode.color });
            },
            [mode]
        ),
    };

    const theme = useMemo(
        () =>
            createTheme({
                palette: getTheme(mode),
            }),
        [mode]
    );

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </ColorModeContext.Provider>
    );
};
