import DeleteIcon from "@mui/icons-material/Delete";
import KeyIcon from "@mui/icons-material/Key";
import { IconButton, Tooltip } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import React from "react";
import { Instance } from "../types";
import { Product } from "@types";
import { DeviceActivationType } from "@components/Shared";

interface InstanceProps extends Instance {
    handleDeleteInstance: (instance: Instance) => void;
    handleEditInstance: (instance: Instance) => void;
    product: Product;
}
export const InstancesTableData: React.FC<InstanceProps> = ({
    instance_id: id,
    licensed,
    handleDeleteInstance,
    handleEditInstance,
    product,
}) => (
    <TableRow key={id}>
        {licensed ? (
            <TableCell sx={{ fontSize: "16px", color: "green" }} align="left">
                {<DeviceActivationType product={product} />}
            </TableCell>
        ) : (
            <TableCell sx={{ fontSize: "16px", color: "#ef5350" }} align="left">
                Inactive
            </TableCell>
        )}
        <TableCell align="right">
            <Tooltip title="Edit Status" sx={{ display: "inline-block" }}>
                <IconButton sx={{ p: "4px" }} onClick={() => handleEditInstance({ instance_id: id, licensed })}>
                    <KeyIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="Delete Instance" sx={{ display: "inline-block" }}>
                <IconButton sx={{ p: "4px" }} onClick={() => handleDeleteInstance({ instance_id: id, licensed })}>
                    <DeleteIcon />
                </IconButton>
            </Tooltip>
        </TableCell>
    </TableRow>
);
